<template>
  <div style="background-color: #f5f5f5">

<!--    系统简介-->
    <el-row>
      <el-col :span="18" :offset="3">
        <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(1)">磐石产品</span> &gt; <span>视频运维安全管理平台</span></p>
      </el-col>
      <el-col :span="18" :offset="3">
        <p class="currencyTitle">视频运维安全管理平台</p>
        <div class="frameworkList xitong">
          <p>目前，各行业视频监控应用迅速提升，视频应用的快速普及对维护社会治安以及保障安全生产等起到了至关重要的作用。</p>
          <p>公共安全领域，视频监控系统对于打击犯罪和提高公安机关案件侦破效率正在发挥着越来越重要的作用，监控系统的健康运行状态（在线率、视频质量等）逐渐成为各级公安机关重点关注的课题，传统依靠人力以及简单平台统计的模式已经不能很好满足新阶段平台运维的要求，视频监控系统的高速发展需要更加高效和智能的运维手段与其相匹配。</p>
          <p>除公共安全外，金融保险、政府服务窗口、学校、医院、智能制造、智慧社区等相关领域，大规模视频应用的比例不断提高。视频监控的引入使用对规范运营以及安全保障等发挥出重要作用，并取得良好社会效果，智能化运维系统更是对该应用成果的有力保障。</p>
          <p>磐石多年来在平安城市工程建设实践中，凭借着丰富的实施以及运维经验，不断优化运维方案，通过引入使用数学建模技术、多维算法技术、计算机智能视觉技术等，打造出一套集故障即时发现、故障精准定位、成像质量分析、实时调度、动态资产平衡、自定义报表以及全自动主动巡检等众多功能于一身的“视频运维安全管理系统”，实现了对视频监控系统健康运行全生命周期的精准管理。</p>
          <p>磐石视频运维安全管理系统通过灵活构建包括全面兼容的探针系统、支持多种智能算法的调度单元以及高效便捷的移动终端系统，可全面支持各种监控系统日常运维的一般要求，更能提供丰富的定制和增值功能，满足未来全面智能、自动高效的各种运维需求。</p>
          <img src="../../assets/yunwei/xitong.png">
        </div>
      </el-col>
    </el-row>

<!--    功能简介-->
    <el-row>
      <el-col :span="18" :offset="3">
        <p class="currencyTitle">系统结构</p>
        <div class="frameworkList" style="background: #ECECEC;">
          <img src="../../assets/yunwei/gongneng.png">
        </div>
      </el-col>
    </el-row>

<!--    探针-->
    <el-row>
      <el-col :span="18" :offset="3">
        <p class="currencyTitle">探针</p>
        <div style="padding: 5%;background-color: #fff">
          <p class="tz1">探针系统实现与终端设备的直接握手，通过内建自动适配MIB库，全面兼容市场上主流摄像机产品，同时支持与各主流平台的无缝对接。系统采用“多维”探测机制，准确掌握设备状态，通过应用数据建模、智能分析以及自学习等关键技术，实现对异常状态的精准研判，目前支持的主要故障类型包括：供电故障、传输故障、摄像机故障、云台失效以及各种成像质量在线检测等。</p>
          <el-row class="tanzhenTop">
            <el-col :span="16"><img src="../../assets/yunwei/tanzhen3.png" style="width:90%"></el-col>
            <el-col :span="8" class="tanzhenTopPara">
              <p>探针是运维平台实现信息获取以及进行智能研判的关键设备，该系统采用“安全工控平台”架构，通过双平台一体化设计，采用超小指令集摆渡技术和数字化加密算法，生产数据与逻辑数据物理隔离，实现安全调度。</p>
              <p>探针系统提供多种部署方式，可灵活部署到视频监控系统的任意节点，通过内嵌网格管理模型，支持对全局或指定范围进行有效运维管理。</p>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 调度单元 -->
    <el-row>
      <el-col :span="18" :offset="3">
        <p class="currencyTitle">调度单元</p>
        <el-row class="tanzhenTop" style="padding: 5%;background-color: rgb(255, 255, 255);">
          <el-col :span="8" class="tanzhenTopPara" style="padding-top:7%;padding-left:3%;">
            <p>调度单元是视频运维安全管理系统的管理中枢，负责全面解析运行数据，同时实现电子地图、资产管理、运维调度、报表统计、绩效考核、网络管理以及运营管理等丰富功能。</p>
          </el-col>
          <el-col :span="16"><img src="../../assets/yunwei/tanzhen2.png" style="width:90%;display:block;margin:0 auto;"></el-col>
          <ul class="tanzhenBottomList">
            <li class="tanzhenBottomListLi" v-for="(item,index) in tanzhenList" :key="index">
              <img :src="item.imgSrc">
              <p class="tanzhenBottomListLip">{{item.title}}</p>
              <p>{{item.desc}}</p>
            </li>
          </ul>
        </el-row>
      </el-col>
    </el-row>

<!--    运维通-->
    <el-row>
      <el-col :span="18" :offset="3">
        <p class="currencyTitle">运维通</p>
        <el-row style="background:#EFEFEF;padding:5%;">
          <el-col :span="8" class="ywa"><img src="../../assets/yunwei/yunweitong01.png"></el-col>
          <el-col :span="16" class="ywtTop">
            <p class="ywb">“运维通”是视频运维安全管理系统的移动客户端部分，同步支持andriod和ios，分为“工程端”和“管理端”两个版本，适应不同的使用人员，主要提供如下功能：</p>
            <ul class="ywtTopList">
              <li>
                <p class="ywtTitle">地图在线</p>
                <p class="ywtDesc">“运维通”集成了电子地图功能，可以通过电子地图实时查看选定区域摄像机或者任务分布等，“工程端”支持在线规划任务执行，“管理端”主要面向公安用户，可以在线规划重点保障路线和具体监控点位等。</p>
              </li>
              <li>
                <p class="ywtTitle">任务管理</p>
                <p class="ywtDesc">任务管理主要面向工程技术人员，运维人员可查看任务列表，可选择接受任务或拒接任务。系统支持一键导航功能，支持自动推送抢修线路等。维修任务完成后支持在线确认维修结果。</p>
              </li>
            </ul>
          </el-col>
        </el-row>
        <el-row style="background:#EFEFEF;padding:5%;padding-top:0;">
          <el-col :span="16">
            <ul class="ywtBottomList">
              <li>
                <p class="ywtTitle">任务保障</p>
                <p class="ywtDesc">任务保障主要面向公安用户，为保障重要任务，将相关摄像机加入重点保障序列，系统可发起实时探测任务计划，随时发现故障随时调度，管理人员可在线查看完整维护过程，切实掌握维修进度，准确估算修复时间等。</p>
              </li>
              <li>
                <p class="ywtTitle">即时通信</p>
                <p class="ywtDesc">“运维通”嵌入了即时通信功能，支持文字、语音、图片以及短视频等功能，即时通信既可以满足移动客户端与调度中心的即时沟通，同时也可满足移动客户端之间的信息互通。</p>
              </li>
              <li>
                <p class="ywtTitle">主动巡检</p>
                <p class="ywtDesc">主动巡检提供了运维人员常规巡检的全部功能，既支持按照预设路线巡检，又支持任意区域巡检。巡检过程可自动探测0-500米范围内设备运行状态，可拍摄现场照片或录制现场图像并上传调度中心。</p>
              </li>
            </ul>
          </el-col>
          <el-col :span="8">
            <img src="../../assets/yunwei/yunweitong02.jpg" style="width: 100%">
          </el-col>
        </el-row>
      </el-col>
    </el-row>

<!--    系统优势-->
    <el-row>
      <el-col :span="18" :offset="3">
        <p class="currencyTitle">系统优势</p>
        <div class="frameworkList xitong" style="padding:2% 6%;">
          <p>运维管理高效便捷。故障从发生到调度指令下达时间≤2.6Min，修复确认时间≤1.5Min。</p>
          <p>支持多任务并发处理，高效探测策略可支持故障信息零延迟发布，并发轮巡总量≥2000条/秒；</p>
          <p>精准故障主动研判。系统内建图像参数指标模型，支持阈值自定义，主动关联运行环境信息，多帧图像横向比对，精准判断成像质量；提供遮挡、图像缺失、冻结、云台工况等状态预警；全面智能调度，工作进程可视化管理，关键节点障碍提供预警并支持人工干预等；提供全方位管理功能，支持在线信息采集、标绘、动态展示等……</p>
          <p>“硬”安全保障。系统采用双平台一体化设计，生产数据与逻辑数据物理隔离。引用超小指令集和数字化加密算法，调度指令单向发布，维修效果智能判断。 全面兼容，灵活部署。</p>
          <p>系统全面支持各种品牌摄像机和平台产品，支持新旧系统的无缝对接，尤其适合复杂视频监控系统运维要求，可有效提高设备在线率以及提升视频质量，充分做到故障精准定位、合理调配运维资源、有效降低日常运维成本。</p>
          <img src="../../assets/yunwei/youshi.jpg" style="width:100%;margin-top: 0;">
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "yunwei",
  data(){
    return{
      proFramework:[{title:'服务组件',imgSrc:require('@/assets/panshProduct/application/jiagou01.png'),},
        {title:'服务组件解释',imgSrc:require('@/assets/panshProduct/application/jiagou02.png'),}],
      tanzhenList:[
          {imgSrc:require('@/assets/yunwei/tanzhen01.png'),title:'电子沙盘',desc:'实现对视频监控系统全局或者任意预设区域的在线展示，支持实时显示视频采集终端设备位置、运行状态（不同注释颜色）等信息，支持在线动态标绘和对资产进行管理；可实时在线运维调度，可为布控以及监控终端选址等提供科学依据。'},
          {imgSrc:require('@/assets/yunwei/tanzhen02.png'),title:'智能调度',desc:'调度单元支持手动和自动两种任务调度方式，支持自由定义任务调度时间，支持紧急任务强插调度，支持对已调度任务推进状态进行实时跟踪和效率评定等。'},
          {imgSrc:require('@/assets/yunwei/tanzhen03.png'),title:'报表统计',desc:'调度单元提供了丰富的报表功能，支持自定义报表内容，可自由选择报表模板，支持报表的打印和PDF导出等。'},
          {imgSrc:require('@/assets/yunwei/tanzhen04.png'),title:'绩效考核',desc:'调度单元全面采集从任务发起到任务完结的过程数据，统计分析任务接收效率、任务处置效率、任务处置效果等业务数据，实现对运维人员工作效能的科学考核。'},
          {imgSrc:require('@/assets/yunwei/tanzhen05.png'),title:'资产管理',desc:'调度单元支持对资产按照“生产状态”、“备用状态”以及“维修状态”等不同阶段和状态模式进行信息统计，充分体现类项目运维特点，通过对生产、备用和维修等状态设备的动态跟踪，实现资产管理的动态平衡功能。'},
          {imgSrc:require('@/assets/yunwei/tanzhen06.png'),title:'网络管理',desc:'调度单元集成了网络管理功能，支持自动检索网络节点设备和智能分析其拓扑结构，支持对节点设备主要性能指标和运行状态进行实时跟踪，同时提供了有效预警和告警机制。'},
      ]
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('OperationsMobile')
    }
  },
  methods: {
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changeNav(navNum){
        this.$store.dispatch('changeShow',navNum)
      },
  },
}
</script>

<style scoped>
/* 通用样式 */
li{
  list-style: none;
}
/* 导航栏 */
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
/* 页面样式 */
.currencyTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
  margin-top: 3%;
  margin-bottom: 2%;
}
.frameworkList{
  text-align: center;
  background-color: #fff;
  padding: 5%;
}
.frameworkList img{
  max-width: 100%;
}
.currencyLittleTit{
  font-size: 36px;
  color: #101010;
}
/*系统简介区块*/
.xitong img{
  width: 60%;
  margin-top: 3%;
}
.xitong p{
  text-align: justify;
  color: #888;
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
}

/*探针*/
.tz1{
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
  margin-bottom: 3%;
  color: #888;
}
.tanzhenTopPara p{
  font-size: 16px;
  color: #000;
  line-height: 28px;
  text-indent: 2em;
  margin: 3% 0;
  color: #888;
}
.tanzhenBottomList{
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}
.tanzhenBottomListLi{
  width: 30%;
  margin: 0 1%;
}
.tanzhenBottomListLip{
  padding-bottom: 15px;
  border-bottom: 2px dashed #000;
}
.tanzhenBottomListLi p{
  margin: 5% 0;
}
.tanzhenBottomListLi p:last-child{
  text-align: justify;
  color: #888;
  line-height: 26px;
}

/*运维通*/
.ywtTitle{
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}
.ywa img{
  margin-left: 10%;
  width: 88%;
  max-width: 216px;
}
.ywb{
  text-indent: 2em;
  font-size: 16px;
  color: #888;
}
.ywtDesc{
  font-size: 16px;
  color: #888;
  text-align: justify;
  text-indent: 2em;
  padding-top: 2%;
  line-height: 26px;
}
.ywtTopList li{
  margin-top: 3%;
}
.ywtBottom{
  position: relative;
}
.ywtBottomList{
  width: 90%;
  margin-left: 5%;
}
.ywtBottomList li{
  margin: 5% 0;
}
</style>